import React from "react";

const NotFound = () => {
    return(
        <div className="not-found">
            <h3 className="display-3">Page Not Found</h3>
        </div>
    );
};

export default NotFound;