import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="container text-light">
            <div className="py-4">
                <div className="jumbotron">
                    <h1 className="display-4 mb-5">Privacy Policy</h1>
                    <p className="lead">JARO4Me Corporation (“JARO4Me”) values privacy, so your privacy is very important to us! Accordingly, we have developed this Policy in order for you to understand how we collect, use, communicate and disclose and make use of personal information. The following outlines our privacy policy. </p>
                    <p className="lead">We will collect and use personal information only when necessary, solely with the objective of fulfilling those purposes described by the current Terms of Service and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.  JARO provides Services to a general audience not intended for use by children. When using any of our Services you consent to the collection, transfer, storage, disclosure, and use of your information as described in this Privacy Policy. However, for persons under 13 years of age parental permission is required to give personal information to JARO.</p>
                    <p className="lead">Before or at the time of collecting any personal information, we will identify the purposes for which information is being collected. </p>
                    <p className="lead">For example, information that personally identifies you that we collect include: </p>
                    <ul>
                        <li>information such as your name, address, phone number and e-mail address, and other demographic information (such as date of birth, gender, age, and interests) that you provide by completing forms on the Sites, including if you register as a user of the Services, subscribe to our mailing lists, upload or submit any material through the services, or request any information;</li>
                        <li>your log-in and password details in connection with the account sign-in process;</li>
                        <li>details of any requests or transactions made by you through the Service; and</li>
                        <li>communications you send to us, for example to report a problem or to submit queries, concerns, or comments regarding the Service or its content;</li>
                    </ul>
                    <p className="lead">We will use this information that personally identifies you to:</p>
                    <ul>
                        <li>identify you when you sign-in to your account;</li>
                        <li>enable us to provide you with the Services or to carry out your instructions (for example, to process payment information);</li>
                        <li>administer your account with us;</li>
                        <li>enable us to contact you regarding any question you make through the Service; and</li>
                        <li>use for other purposes that we may disclose to you when we request your information.</li>
                    </ul>
                    <p className="lead">In addition, we automatically receive and store certain types of technical and non-personal information whenever you interact with our products and services. This helps us enhance your experience, but this information does not identify you personally — you remain anonymous.</p>
                    <p className="lead"> Examples of such information we may collect include your IP address, cookie information, device hardware information, browser type and language, system type, whether you have enabling software to access certain features, referring website URLs, and other information about your interactions with us. This information helps us keep you logged in, remember your preferences, confirm delivery of email communications, and generally observe how people are using our products and services.</p>
                    <p className="lead">We may use a variety of methods to collect this information, including cookies, pixel tags or other methods. We don't use cookies to create a unique profile of you or to collect personal information from you. We don't track your information across the Internet.</p>
                    <p className="lead">The information we gather enables us to provide you with our products and services and to help administer your account. You consent to our use of your information to do any of the following:</p>
                    <ul>
                        <li>Enable you to use and navigate our products, services, features and other activities;</li>
                        <li>
                            Enhance, customize and personalize your JARO experiences and communications; and
                        </li>
                        <li>Operate, provide, improve and maintain our products and services, including analyzing anonymous user behavior.</li>
                    </ul>
                    <p className="lead">We may also store some information locally on your device. For example, we may store information as local cache so that you can open the app and view content faster.</p>
                    <p className="lead">We will only retain personal information as long as necessary for the fulfillment those purposes prescribed by law.  Otherwise, after a maximum of 10 days we may start the process of deleting, de-identifying, or aggregating portions of your personal information, which is usually instantaneous but in some cases may take up to a week.</p>
                    <p className="lead">Finally, we can’t guarantee that messages and corresponding metadata will be deleted within a specific timeframe. Keep in mind that we may also retain certain information in backup for a limited period of time or as required by law. This is true even after we’ve deleted messages and corresponding metadata from our servers. We also sometimes receive requests from law enforcement requiring us by law to suspend our ordinary server-deletion practices for specific information. Finally, of course, as with any digital information, there may be ways to access messages while still in temporary storage on recipients’ devices or, forensically, even after they are deleted.</p>
                    <p className="lead">We will protect personal information by security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.  Please note, however, that regardless of these industry-leading efforts, we cannot 100% guarantee the security or confidentiality of the information you provide to us over the Internet or through electronic storage. We are not responsible for the actions of others or the circumvention of any privacy settings or security measures. If we discover a security breach that compromises your personal information, we will inform you in accordance with applicable law.</p>
                    <p className="lead">We collect and use limited data with Google Analytics on our corporate site (watchjaro.com), as well as in a sandboxed iframe for jaro4me.im (the JARO4Me application), which then self-destructs after 10 seconds. Our backend logs the total number TRANSACTIONS started, number of messages sent, beta-list sign-ups, and incoming requests.</p>
                    <p className="lead">We do not and will never view, log, or transfer plaintext versions of your encrypted data, nor the private keys and mutually shared secrets needed in order to decipher them.We have not been approached by any agency, government or otherwise, with a request to backdoor our code. We are currently working on implementing a better warrant canary system. We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained.</p>
                    <p className="lead">However, please note that we may disclose information we have collected when we believe that disclosure is necessary to comply with law, to enforce or apply our Terms of Use and other agreements, or to protect the rights, property or safety of us, our employees, affiliates, users or others, and defend ourselves against third-party claims. This may include exchanging information with other companies and organizations for fraud protection and credit risk reduction. We may also disclose information upon governmental request or in response to a court order.</p>
                    <p className="lead">Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website or other websites. The Third-party vendors’, including Google's, use of advertising cookies enables them and their partners to serve ads to you based on your visits to your sites and/or other sites on the Internet. For Google you may opt out of personalized advertising by visiting Ads Settings. (Alternatively, you can opt out of a third-party vendor's use of cookies for personalized advertising by visiting www.aboutads.info).</p>
                    <p className="lead">Note as a general matter JARO4Me does not opted out of third-party ad serving. Thus, the cookies of Google as well as other third-party vendors or ad networks may also be used to serve ads on JARO4Me’s site. Further, note that you may visit the websites of Google as well as the other third-party vendors to opt out of the use of cookies for personalized advertising (if the Google or the vendor or ad network offers this capability). Alternatively, you can opt out of some third-party vendors’ uses of cookies for personalized advertising by visiting www.aboutads.info.</p>
                    <p className="lead">If your information is requested by a legal authority for any reason, we will notify you by email unless legally prevented from doing so.</p>
                    <p className="lead">JARO4Me does not knowingly collect or solicit personal information from children under 13.  if you become aware that your child has provided us with personal information without your consent, please contact us at privacy@jaroservices.net. If JARO4Me becomes aware that a child under 13 has provided us with personal information, JARO4Me will take steps to remove such information and terminate the child’s account.</p>
                    <p className="lead">For those living outside the United States, please note our operations are conducted, in whole or in part, in the United States.  Regardless of where you live in the world, you consent to have your personal data and information transferred, processed and stored in the United States, and to allow us to use and collect your personal information in accordance with this Privacy Policy.</p>
                    <p className="lead">General Data Protection Regulation (GDPR) EU Residents:</p>
                    <p className="lead">If you are a resident of the European Union ("EU"), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the "GDPR") with respect to your Personal Data, as outlined below.</p>
                    <p className="lead">For this section, we use the terms "Personal Data" and "processing" as they are defined in the GDPR, but "Personal Data" generally means information that can be used to individually identify a person, and "processing" generally covers actions that can be performed in connection with data such as collection, use, storage and disclosure. The term Company as used hereinafter shall designate the controller of your Personal Data processed in connection with the Services being provide. Further, note the processor of your Personal Data processes your Personal Data (i.e., not the controller) in connection with the provision of services to customers. JARO4me may serve as the controller of your Personal Data or the processor of your Personal Data. If JARO4Me is the processor of your Personal Data (i.e., not the controller), please contact the controller party in the first instance to address your rights with respect to such data.</p>
                    <p className="lead">If there are any conflicts between this section and any other provision of this Privacy Policy, the policy or portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether any of the following applies to you, please contact us at privacy@jaroservices.net.</p>
                    <p className="lead">What Personal Data Do We Collect From You?</p>
                    <p className="lead">We collect Personal Data about you when you provide such information directly to us, when third parties such as our business partners or service providers provide us with Personal Data about you, or when Personal Data about you is automatically collected in connection with your use of our Services.</p>
                    <p className="lead">Information we collect directly from you: We receive Personal Data directly from you when you provide us with such Personal Data, including without limitation the following:</p>
                    <ul>
                        <li>First and last name</li>
                        <li>Company name, VAT number</li>
                        <li>Email address</li>
                        <li>Mailing address</li>
                        <li>Telephone number</li>
                        <li>Address</li>
                        <li>IP Address</li>
                        <li>Username</li>
                        <li>Account Code (can include email addresses)</li>
                        <li>Browser information</li>
                        <li>Credit card and credit card CVV</li>
                        <li>Transaction and Billing Data</li>
                    </ul>
                    <p className="lead">Information we receive from third party sources: Some third parties such as our business partners and service providers provide us with Personal Data about you, such as the following: </p>
                    <ul>
                        <li>First and last name</li>
                        <li>Email address</li>
                        <li>Mailing address</li>
                        <li>IP Address</li>
                        <li>Device identifiers</li>
                        <li>Web browser information</li>
                        <li>Page view statistics</li>
                        <li>Browsing history</li>
                        <li>Usage information</li>
                    </ul>
                    <p className="lead">Account information for third party services: If you interact with a third party service when using our Services, such as if you use a third party service to log-in to our Services (e.g., Facebook or Twitter), or if you share content from our Services through a third party social media service, the third party service will send us information about you, such as information from your public profile, if the third party service and your account settings allow such sharing. The information we receive will depend on the policies and your account settings with the third party service.</p>
                    <p className="lead">Information we automatically collect when you use our Services: Some Personal Data is automatically collected when you use our Services, such as the following:</p>
                    <ul>
                        <li>IP Address</li>
                        <li>Device identifiers</li>
                        <li>Web browser information</li>
                        <li>Page view statistics</li>
                        <li>Browsing history</li>
                        <li>Usage information</li>
                        <li>Transaction information (e.g. transaction amount, date and time such transaction occurred)</li>
                        <li>Cookies and other tracking technologies (e.g. web beacons, pixel tags, SDKs, etc.)</li>
                        <li>Location information (e.g. IP address, zip code)</li>
                        <li>Log data (e.g. access times, hardware and software information), etc.</li>
                    </ul>
                    <p className="lead">How Do We Use Your Personal Data? We process Personal Data to operate, improve, understand and personalize our Services. For example, we use Personal Data to:</p>
                    <ul>
                        <li>Create and manage user profiles</li>
                        <li>Communicate with you about the Services</li>
                        <li>Process orders</li>
                        <li>Contact you about Service announcements, updates or offers</li>
                        <li>Provide support and assistance for the Services</li>
                        <li>Personalize website content and communications based on your preferences</li>
                        <li>Meet contract or legal obligations</li>
                        <li>Respond to user inquiries</li>
                        <li>Fulfill user requests</li>
                        <li>Comply with our legal or contractual obligations</li>
                        <li>Resolve disputes</li>
                        <li>Protect against or deter fraudulent, illegal or harmful actions</li>
                        <li>Enforce our Terms of Service</li>
                    </ul>
                    <p className="lead">We will only process your Personal Data if we have a lawful basis for doing so. Lawful basis for processing include consent, contractual necessity and our "legitimate interests" or the legitimate interest of others, as further described below.</p>
                    <p className="lead">Contractual Necessity: We process the following categories of Personal Data as a matter of “contractual necessity”, meaning that we need to process the data to perform under our Terms of Service with you, which enables us to provide you with the Services. When we process data due to contractual necessity, failure to provide such Personal Data will result in your inability to use some or all portions of the Services that require such data.</p>
                    <ul>
                        <li>First and last name</li>
                        <li>Email address</li>
                        <li>Mailing address</li>
                        <li>IP Address</li>
                        <li>Credit card and credit card CVV</li>
                        <li>Transaction and Billing Data</li>
                    </ul>
                    <p className="lead">Legitimate Interest: We process the following categories of Personal Data when we believe it furthers the legitimate interest of us or third parties.</p>
                    <ul>
                        <li>First and last name</li>
                        <li>Email address</li>
                        <li>Mailing address</li>
                    </ul>
                    <p className="lead">Examples of these legitimate interests include:</p>
                    <ul>
                        <li>Operation and improvement of our business, products and services</li>
                        <li>Marketing of our products and services</li>
                        <li>Provision of customer support</li>
                        <li>Protection from fraud or security threats</li>
                        <li>Compliance with legal obligations</li>
                        <li>Completion of corporate transactions</li>
                    </ul>
                    <p className="lead">Consent: In some cases, we process Personal Data based on the consent you expressly grant to us at the time we collect such data. When we process Personal Data based on your consent, it will be expressly indicated to you at the point and time of collection.</p>
                    <p className="lead">Other Processing Grounds: From time to time we may also need to process Personal Data to comply with a legal obligation, if it is necessary to protect the vital interests of you or other data subjects, or if it is necessary for a task carried out in the public interest.</p>
                    <p className="lead">How and With Whom Do We Share Your Data? We share Personal Data with vendors, third party service providers and agents who work on our behalf and provide us with services related to the purposes described in this Privacy Policy or our Terms of Service. These parties include:</p>
                    <ul>
                        <li>Payment processors</li>
                        <li>Fraud prevention service providers</li>
                        <li>Ad networks</li>
                        <li>Analytics service providers</li>
                        <li>Staff augmentation and contract personnel</li>
                        <li>Hosting service providers</li>
                        <li>Co-location service providers</li>
                        <li>Telecommunications service providers</li>
                    </ul>
                    <p className="lead">We also share Personal Data when necessary to complete a transaction initiated or authorized by you or provide you with a product or service you have requested. In addition to those set forth above, these parties also include:</p>
                    <p className="lead">Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Services)</p>
                    <p className="lead">Social media services (if you interact with them through your use of the Services) Third party business partners who you access through the Services</p>
                    <p className="lead">Other authorized parties. We also share information with third parties when you have given us consent to do so (as indicated at the point such information is collected).</p>
                    <p className="lead">We also share Personal Data when we believe it is necessary to:</p>
                    <ul>
                        <li>Comply with applicable law or respond to valid legal process, including from law enforcement or other government agencies</li>
                        <li>Protect us, our business or our users, for example to enforce our terms of service, prevent spam or other unwanted communications and investigate or protect against fraud</li>
                        <li>Maintain the security of our products and services</li>
                        <li>Furthermore, if we choose to buy or sell assets, user information is typically one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party, and we would share Personal Data with the party that is acquiring our assets. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your Personal Information as set forth in this policy</li>
                    </ul>
                    <p className="lead">How Long Do We Retain Your Personal Data? We retain Personal Data about you in accordance with legal and compliance requirements. In some cases we retain Personal Data for longer than any stated requirements, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, we may retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</p>
                    <p className="lead">What Security Measures Do We Use? We seek to protect Personal Data using appropriate technical and organizational measures based on the type of Personal Data and applicable processing activity. For example, we use encryption and tokenization to keep your Personal data secure.</p>
                    <p className="lead">What Rights Do You Have Regarding Your Personal Data? You have certain rights with respect to your Personal Data, including those set forth below. For more information about these rights, or to submit a request, please email privacy@jaroservices.net. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need to you to provide us with additional information, which may include Personal Data, if necessary to verify your identity and the nature of your request.</p>
                    <p className="lead">Access: You can request more information about the Personal Data we hold about you and request a copy of such Personal Data. Rectification: If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data.</p>
                    <p className="lead">Erasure: You can request that we erase some or all of your Personal Data from our systems.</p>
                    <p className="lead">Withdrawal of Consent: If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services.</p>
                    <p className="lead">Portability: You can ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.</p>
                    <p className="lead">Objection: You can contact us to let us know that you object to the further use or disclosure of your Personal Data for certain purposes. Restriction of Processing: You can ask us to restrict further processing of your Personal Data.</p>
                    <p className="lead">Right to File Complaint: You have the right to lodge a complaint about Company's practices with respect to your Personal Data with the supervisory authority of your country or EU Member State.</p>
                    <p className="lead">Transfers of Personal Data: The Services are hosted and operated in the United States (“U.S.”) through Company and its service providers, and laws in the U.S. may differ from the laws where you reside. By using the Services, you acknowledge that any Personal Data about you, regardless of whether provided by you or obtained from a third party, is being provided to Company in the U.S. and will be hosted on U.S. servers, and you authorize Company to transfer, store and process your information to and in the U.S., and possibly other countries. You hereby consent to the transfer of your data to the U.S.</p>
                    <p className="lead">What If You Have Questions Regarding Your Personal Data? If you have any questions about this section or our data practices generally, please contact us using the following information: privacy@jaro4me.com</p>
                    <p className="lead">Finally, please note JARO4Me may revise this Privacy Policy from time to time without prior notice. If you have any questions about our policy, please email us at privacy@jaro4me.com.</p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;