import React from "react";
import bannerImg from "../../images/banner.jpg";

const About = () => {
    return (
        <div className="w-100">
            
            <div className="container text-light">
                <div className="py-4">
                    <div className="jumbotron">
                        <h1 className="display-4 mb-5">About Us!</h1>
                        <p className="lead">Jaro4me is a multi-faceted news portal for the community. Our platform opens the doorway for the African diaspora to connect, digitize their lives, and receive curated content that is important to them. </p>
                        <p className="lead">Taking its name from Mount Kilimanjaro, the highest mountain peak on the African continent, Jaro4me magazine offering features insightful and educational editorials created for those who identify with Black culture. Featuring film, arts & culture, books, music, and more, we are your one-stop destination for content that both entertains and informs. </p>
                        <p className="lead">Our My Jaro4me offering will allow users to receive premium services such as mail, video conferencing, contacts, calendar, and drive storage space. </p>
                        <p className="lead">Currently, we are a small group of independent writers and developers with a passion for sharing and educating about Black culture. We hope that you, as a subscriber, will grow with us on this journey. </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;