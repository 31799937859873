import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer custom-footer mt-auto py-4 bg-black">
            <div className="container text-center">
                <span className="text-light fs-6">© Copyright 2021, All Rights Reserved </span>
            </div>
        </footer>
    );
};

export default Footer;