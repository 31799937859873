import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import About from './components/pages/AboutUs';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsAndCondition from './components/pages/TermsAndCondition';
import Navbar from './components/layout/Navbar';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NotFound from './components/pages/NotFound';
import Footer from './components/layout/Footer';

function App() {
  return (
    <Router>
      <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={About} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/termsandcondition" component={TermsAndCondition} />
        <Route component={NotFound} />
      </Switch>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;