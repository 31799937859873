import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../images/Jarocorp-HD2.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-black navbar-border-bottom">
            <div className="container">
                <Link className="navbar-brand" exact="true" to="/">
                    <img src={logo} className="img-fluid" width="120" alt="" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" exact="true" to="/">About Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact="true" to="/privacypolicy">Privacy Policy</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact="true" to="/termsandcondition">Terms of Service</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;